import axios from 'axios';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import { API_URL } from '../../config';

const Home = () => {
  const [companyLimits, setCompanyLimits] = useState(null);
  const [dailySpendLimit, setDailySpendLimit] = useState('');
  const [companyTheme, setCompanyTheme] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { collapsed } = useSidebarState();

  const containerClass = collapsed ? "homeContainer collapsed" : "homeContainer";

  useEffect(() => {
    const fetchCompanyTheme = async () => {
      if (currentUser && currentUser.email) {
        try {
          const response = await axios.get(`${API_URL}/truesightpage/company_theme?email=${currentUser.email}`);
          setCompanyTheme(response.data);
        } catch (error) {
          console.error('Error fetching company theme:', error);
        }
      }
    };

    fetchCompanyTheme();
  }, [currentUser]);

  useEffect(() => {
    if (companyTheme) {
      document.documentElement.style.setProperty('--sidebar-bg-color', companyTheme.sidebar_background_color);
      document.documentElement.style.setProperty('--sidebar-text-color', companyTheme.sidebar_text_color);
      document.documentElement.style.setProperty('--sidebar-hover-color', companyTheme.sidebar_hover_color);
      document.documentElement.style.setProperty('--text-color', companyTheme.text_color);
    }
  }, [companyTheme]);

  // Asegúrate de que esta función esté definida en tu componente
  const getCompanyFromEmail = (email) => {
    const domain = email.split('@')[1];
    return domain.split('.')[0];
  };

  const truesightpage = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    }
  });

  useEffect(() => {
    const fetchCompanyLimits = async () => {
      if (currentUser && currentUser.email) {
        const company = getCompanyFromEmail(currentUser.email);
        try {
          const response = await truesightpage.get(`/truesightpage/company_limits/${company}`);
          console.log('Response:', response);
          if (response.data) {
            setCompanyLimits(response.data);
            setDailySpendLimit(response.data.daily_spend_limit);
          }
        } catch (error) {
          console.error("Error fetching company limits:", error.response || error);
        }
      }
    };

    fetchCompanyLimits();
  }, [currentUser]);

  const handleUpdateLimit = async () => {
    if (companyLimits && currentUser && currentUser.email) {
      if (parseFloat(dailySpendLimit) <= 0) {
        alert("Por favor, introduce una cifra válida mayor que cero para el límite de gasto diario.");
        return;
      }

      const company = getCompanyFromEmail(currentUser.email);
      try {
        console.log(company, companyLimits.current_spend, parseFloat(dailySpendLimit), currentUser.email)
        await truesightpage.post(`/truesightpage/company_limits`, {
          company: company,
          current_spend: companyLimits.current_spend,
          daily_spend_limit: parseFloat(dailySpendLimit),
          last_user: currentUser.email  // Añadimos el email del usuario que realiza la actualización
        });
        alert("Límite de gasto diario de la empresa actualizado con éxito.");
        // Actualizar el estado local con el nuevo límite
        setCompanyLimits({
          ...companyLimits,
          daily_spend_limit: parseFloat(dailySpendLimit),
          last_user: currentUser.email
        });
      } catch (error) {
        console.error("Error updating company daily spend limit:", error);
        alert("Hubo un error al actualizar el límite de gasto diario de la empresa.");
      }
    }
  };

  const handleDailySpendLimitChange = (e) => {
    const value = e.target.value;
    if (value === '' || parseFloat(value) > 0) {
      setDailySpendLimit(value);
    } else {
      alert("Por favor, introduce una cifra válida mayor que cero para el límite de gasto diario.");
    }
  };

  return (
    <div data-testid="sidebar1" className="home">
      <Sidebar />
      <div data-testid="navbar1" className={containerClass}>
        <Navbar />
        <div className="user-greeting">
          <h1>Configuración General</h1>
          <div className="config-section">
            {companyLimits ? (
              <>
                <div className="config-item">
                  <label htmlFor="dailySpendLimit">Límite de gasto diario de la empresa:</label>
                  <input
                    type="number"
                    id="dailySpendLimit"
                    value={dailySpendLimit}
                    onChange={handleDailySpendLimitChange}
                    min="0.01"
                    step="0.01"
                  />
                </div>
                <div className="config-item">
                  <label htmlFor="currentSpend">Gasto actual de la empresa:</label>
                  <input
                    type="number"
                    id="currentSpend"
                    value={companyLimits.current_spend}
                    readOnly
                  />
                </div>
                {companyLimits.last_user && (
                  <div className="config-item">
                    <label>Última actualización por:</label>
                    <span>{companyLimits.last_user}</span>
                  </div>
                )}
                <button onClick={handleUpdateLimit}>Actualizar Límite de Gasto Diario de la Empresa</button>
              </>
            ) : (
              <p>Cargando datos de la empresa...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;