import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWML3KUYH_-QSBcWK3t8dvbudAx2VqSAo",
  authDomain: "truesight-a29b0.firebaseapp.com",
  databaseURL: "https://truesight-a29b0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "truesight-a29b0",
  storageBucket: "truesight-a29b0.appspot.com",
  messagingSenderId: "150587362353",
  appId: "1:150587362353:web:815303f25ad15ae204d910",
  measurementId: "G-W739GJGVKY"
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_KEY2,
//   authDomain: "appsecoex.firebaseapp.com",
//   projectId: "appsecoex",
//   storageBucket: "appsecoex.appspot.com",
//   messagingSenderId: "438187341701",
//   appId: "1:438187341701:web:dd1929c5f49bde22acf6ec",
//   measurementId: "G-BYNVQFYYB2"
// };

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
