import Home from "./pages/home/Home";
import ClientConfig from "./pages/home/ClientConfig.jsx";
import ClientRegistry from "./pages/home/ClientRegistry.jsx";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import ListClientes from "./pages/list/ListClientes";
import ListTiendasCliente from "./pages/list/ListTiendasCliente";
import Single from "./pages/single/Single";
import Details from "./pages/details/Details";
import Documents from "./pages/documents/Documents";
import Trash from "./pages/documents/DeletedDocuments";
import Servicio from "./pages/servicio/Servicio.jsx";
import UpdateServicio from "./pages/servicio/UpdateServicio.jsx";
import New from "./pages/new/New";
import Update from "./pages/update/Update";
import UpdateCliente from "./pages/update/UpdateCliente";
import NewCliente from "./pages/new/NewCliente";
import NewClienteTienda from "./pages/new/NewClienteTienda";
import UpdateTienda from "./pages/update/UpdateTienda";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { clientesInputs, productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { SidebarStateProvider } from "./components/sidebar/SidebarStateProvider.js"
import { AuthContext } from "./context/AuthContext";
import { AuthContextProvider } from "./context/AuthContext";
import PrivacyPolicy from './components//infoBox/PrivacyPolicy';
import Forms from "./pages/list/Forms";
import FormsTable from "./pages/list/FormsTable";
import FormsUpdate from "./pages/update/UpdateForm";
import NotificationsForm from "./pages/list/NotificationsForm";
import ProfileContainer from "./pages/user/ProfileContainer.js";
import ConfigContainer from "./pages/user/ConfigContainer.js";
import EditAdminContainer from "./pages/user/EditAdminContainer.js";
import AddUserContainer from "./pages/user/AddUserContainer.js";
import DataCuadrantes from "./pages/cuadrante/Cuadrante.jsx"
import CuadranteServicio from "./pages/cuadrante/CuadranteServicio.jsx";
import CuadranteVigilante from "./pages/cuadrante/CuadranteVigilantes.jsx";
import GrafanaDashboard from "./pages/home/GrafanaDashboard.jsx";
import { LogoutProvider } from './context/LogoutContext.js'

import { LicenseInfo } from '@mui/x-license-pro';

/**
 * Componente principal de la aplicación.
 */
function App() {
  const { currentUser, adminData } = useContext(AuthContext);
  // Introduce tu clave de licencia aquí
  const licenseKey = '4b00cfcf46586e6cf5eb61f625a41fabTz03OTcwNSxFPTE3MzI5NTYxMTQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
  LicenseInfo.setLicenseKey(licenseKey);
  /**
 * Componente de ruta protegida que requiere autenticación.
 * Redirige al usuario a la página de inicio de sesión si no está autenticado.
 *
 * @param {Object} children - Componente anidado dentro de RequireAuth.
 * @returns {JSX.Element} - Componente RequireAuth.
 */
  const RequireAuth = ({ children, checkRole }) => {
    const tieneAccesoPorRol = () => {
      if (!checkRole || !adminData || !adminData.tipoUsuario) return true;
      return adminData.tipoUsuario.some(role => checkRole.includes(role));
    };

    return currentUser && tieneAccesoPorRol() ? children : <Navigate to="/login" />;
  };

  return (
    <div className="app">
      <BrowserRouter>
        <AuthContextProvider>
          <LogoutProvider>
            <SidebarStateProvider>

              <Routes>
                <Route path="/">
                  <Route path="login" element={<Login />} />
                  <Route path="/servicio" element={<Servicio />} />
                  <Route path="/UpdateServicio" element={<UpdateServicio />} />
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <Home />
                      </RequireAuth>
                    }
                  />
                  <Route path="/clientConfig"
                    index
                    element={
                      <RequireAuth>
                        <ClientConfig />
                      </RequireAuth>
                    }
                  />
                  <Route path="/clientRegistry" element={<RequireAuth><ClientRegistry /></RequireAuth>} />

                  <Route path="/profile"
                    index
                    element={
                      <RequireAuth>
                        <ProfileContainer />
                      </RequireAuth>
                    }
                  />
                  <Route path="/config"
                    index
                    element={
                      <RequireAuth>
                        <ConfigContainer />
                      </RequireAuth>
                    }
                  />
                  <Route path="/GrafanaDashboard"
                    index
                    element={
                      <RequireAuth>
                        <GrafanaDashboard />
                      </RequireAuth>
                    }
                  />
                  {/* <Route path="/add-User"
                  index
                  element={
                    <RequireAuth>
                      <AddUserContainer />
                    </RequireAuth>
                  }
                />
                <Route path="/edit-admin/:adminId"
                  index
                  element={
                    <RequireAuth>
                      <EditAdminContainer />
                    </RequireAuth>
                  }
                />
                <Route path="vigilantes">
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <List />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path=":tipVS"
                    element={
                      <RequireAuth>
                        <Single />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="details/:id"
                    element={
                      <RequireAuth>
                        <Details />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <RequireAuth>
                        <New inputs={userInputs} title="Añadir vigilante" />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="update/:tipVS"
                    element={
                      <RequireAuth>
                        <Update inputs={userInputs} title="Actualizar vigilante" />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route path="tiendas">
                  <Route
                    path="update/:tiendaId"
                    element={
                      <RequireAuth>
                        <UpdateTienda inputs={productInputs} title="Actualizar servicio" />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route path="clientes">
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <ListClientes />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/clientes/:clienteId/tiendas/:nombreCliente"
                    element={
                      <RequireAuth>
                        <ListTiendasCliente />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="newCliente"
                    element={
                      <RequireAuth>
                        <NewCliente inputs={clientesInputs} title="Añadir nuevo cliente" />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/clientes/:clienteId/newClienteTienda/:nombreCliente"
                    element={
                      <RequireAuth>
                        <NewClienteTienda inputs={productInputs} title="Añadir nuevo servicio" />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="update/:clienteId"
                    element={
                      <RequireAuth>
                        <UpdateCliente inputs={clientesInputs} title="Actualizar cliente" />
                      </RequireAuth>
                    }
                  />
                </Route>
              </Route>
              <Route path="documentos">
                <Route
                  index
                  element={
                    <RequireAuth checkRole={['administrativo', 'administrador']}>
                      <Documents />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="papelera">
                <Route
                  index
                  element={
                    <RequireAuth checkRole={['administrativo', 'administrador']}>
                      <Trash />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="notificaciones">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <NotificationsForm />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="form-table">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <FormsTable />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="form-creator">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Forms />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="form-update/:id">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <FormsUpdate />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="cuadrantes">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <DataCuadrantes />
                    </RequireAuth>
                  }
                />
                <Route
                  path="edit-servicio/:id"
                  index
                  element={
                    <RequireAuth>
                      <CuadranteServicio />
                    </RequireAuth>
                  }
                />
                <Route
                  path="edit-vigilante/:id"
                  index
                  element={
                    <RequireAuth>
                      <CuadranteVigilante />
                    </RequireAuth>
                  }
                />
                    */}
                </Route>
              </Routes>
            </SidebarStateProvider>
          </LogoutProvider>
        </AuthContextProvider>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
