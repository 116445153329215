import { useContext, useState } from "react";
import "./login.scss";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { uploadErrorLogToFirestore } from "../../utils/logUtils";
import axios from 'axios';
import logoUrl from "../../images/logoScreen.png";
import { API_URL } from '../../config';

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();

    console.log("Attempting login with email:", email);

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        console.log("User logged in:", user);

        if (user.emailVerified) {
          dispatch({ type: "LOGIN", payload: user });
          window.location.href = '/';
        } else {
          // El correo no está verificado
          alert("Por favor, verifica tu correo electrónico antes de iniciar sesión.");
          // Opcionalmente, ofrecer reenviar el correo de verificación
          const resendeVerificationEmail = window.confirm("¿Quieres que te reenviemos el correo de verificación?");
          if (resendeVerificationEmail) {
            sendEmailVerification(user)
              .then(() => {
                alert("Se ha enviado un nuevo correo de verificación. Por favor, revisa tu bandeja de entrada.");
              })
              .catch((error) => {
                console.log("Error resending verification email:", error);
                alert(`Error al reenviar el correo de verificación: ${error.message}`);
              });
          }
          // Cerrar la sesión del usuario no verificado
          auth.signOut();
        }
      })
      .catch((error) => {
        setError(true);
        console.log("Login error:", error);
        uploadErrorLogToFirestore("Login error: ", error);

        alert(`Error en el inicio de sesión: ${error.message}`);
      });
  };

  const handleResetPassword = async () => {
    if (!email) {
      alert("Por favor, introduce tu correo electrónico.");
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Se ha enviado un correo para restablecer tu contraseña. Por favor, revisa tu bandeja de entrada.");
      })
      .catch((error) => {
        console.log("Reset password error:", error);
        alert(`Error al enviar el correo de recuperación: ${error.message}`);
      });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
  
    console.log("Attempting registration with email:", email);
  
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden. Por favor, inténtalo de nuevo.");
      return;
    }
  
    try {
      // Extraer el dominio y la compañía del email
      const domain = email.split('@')[1];
      const company = domain.split('.')[0];
      console.log("Extracted company:", company);
  
      // Verificar si la compañía ya está registrada en all_users
      const companyResponse = await axios.get(`${API_URL}/truesightpage/all_users/${company}`);
      if (companyResponse.data.length === 0) {
        alert("Esta compañía no está registrada. Por favor, contacta al administrador.");
        return;
      }
  
      // Verificar si el usuario específico ya existe en all_users
      const userExists = companyResponse.data.some(user => user.company_user === email);
  
      // Determinar integration_type basado en los usuarios existentes
      const existingUser = companyResponse.data[0];
      const integration_type = existingUser.integration_type;
  
      // Registrar el usuario en Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("User registered in Firebase:", user);
  
      // Enviar correo de verificación
      await sendEmailVerification(user);
      console.log("Verification email sent");
  
      // Registrar en users_frontend
      await axios.post(`${API_URL}/truesightpage/users_frontend`, {
        username: user.email,
        company: company,
        password: user.uid
      });
      console.log("User registered in users_frontend");
  
      // Registrar en all_users solo si el usuario no existe
      if (!userExists) {
        await axios.post(`${API_URL}/truesightpage/all_users`, {
          integration_user: existingUser.integration_user,
          integration_type: integration_type,
          company: company,
          company_user: user.email,
          company_user_type: 'worker',
          is_final_customer: 1
        });
        console.log("User registered in all_users");
      } else {
        console.log("User already exists in all_users, skipping registration");
      }
  
      // Crear el límite de la empresa si no existe
      try {
        await axios.post(`${API_URL}/truesightpage/company_limits`, {
          company: company,
          current_spend: 0,
          daily_spend_limit: 100 // Puedes ajustar este valor según tus necesidades
        });
        console.log("Company limit created");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          console.log("Company limit already exists");
        } else {
          throw error;
        }
      }
  
      alert("Registro exitoso. Por favor, verifica tu correo electrónico antes de iniciar sesión.");
      setIsRegistering(false); // Volver a la pantalla de inicio de sesión
  
    } catch (error) {
      setError(true);
      console.error("Registration error:", error);
      uploadErrorLogToFirestore("Registration error: ", error);
  
      let errorMessage;
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = "El correo electrónico ya está en uso. Por favor, utiliza un correo electrónico diferente.";
          break;
        case 'auth/weak-password':
          errorMessage = "La contraseña es demasiado débil. Por favor, utiliza una contraseña más fuerte.";
          break;
        case 'auth/invalid-email':
          errorMessage = "El correo electrónico no es válido. Por favor, introduce un correo electrónico válido.";
          break;
        default:
          errorMessage = "Error en el registro: " + (error.message || "Por favor, inténtalo de nuevo.");
      }
      alert(errorMessage);
    }
  };

  const handleForgotPasswordClick = () => {
    setIsResettingPassword(true);
  };

  const handleBackToLogin = () => {
    setIsResettingPassword(false);
  };

  const handleRegisterClick = () => {
    setIsRegistering(true);
  };

  const handleBackToLoginFromRegister = () => {
    setIsRegistering(false);
  };

  return (
    <div className="login">
      <div className="branding">
        <img src={logoUrl} alt="Logo" className="branding-logo" />
        <span className="branding-title">T R U E S I G H T</span>
      </div>
      <div className="loginContainer">
        <h2 className="loginTitle">
          {isResettingPassword ? "Cambiar contraseña" : isRegistering ? "Registrarse" : "Inicia sesión"}
        </h2>

        <form onSubmit={isResettingPassword ? handleResetPassword : isRegistering ? handleRegister : handleLogin} className="loginForm">
          <input
            type="email"
            placeholder="Correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
            className="loginInput"
            data-testid="email-input"
          />
          {!isResettingPassword && (
            <>
              <input
                type="password"
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                className="loginInput"
                data-testid="password-input"
              />
              {isRegistering && (
                <input
                  type="password"
                  placeholder="Confirmar Contraseña"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="loginInput"
                  data-testid="confirm-password-input"
                />
              )}
            </>
          )}

          <button type="submit" className="loginButton">
            {isResettingPassword ? "Recuperar" : isRegistering ? "Registrarse" : "Iniciar Sesión"}
          </button>
        </form>

        {isResettingPassword ? (
          <p className="forgotPassword" onClick={handleBackToLogin}>
            Volver a la pantalla de inicio de sesión
          </p>
        ) : isRegistering ? (
          <p className="forgotPassword" onClick={handleBackToLoginFromRegister}>
            Volver a la pantalla de inicio de sesión
          </p>
        ) : (
          <>
            <p className="forgotPassword" onClick={handleForgotPasswordClick}>
              ¿Has olvidado tu contraseña?
            </p>
            <p className="forgotPassword" onClick={handleRegisterClick}>
              ¿No tienes cuenta? Regístrate aquí
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
