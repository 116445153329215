let API_URL = process.env.REACT_APP_API_URL;

if (typeof window !== 'undefined') {
  const hostname = window.location.hostname;
  if (hostname.includes('shadowtruesight')) {
    API_URL = 'https://www.shadow-truesight.bepart-innova.com';
  } else if (hostname.includes('truesightpage')) {
    API_URL = 'https://www.truesight.bepart-innova.com';
  } else {
    API_URL = 'https://www.dev-truesight.bepart-innova.com';
  }
}

export { API_URL };