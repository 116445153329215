import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import { AuthContext } from "../../context/AuthContext";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import axios from 'axios';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, FormGroup, FormControlLabel, Alert } from '@mui/material';
import { API_URL } from '../../config';

const ClientConfig = () => {
    const [allConfigs, setAllConfigs] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [companyTheme, setCompanyTheme] = useState(null);

    const { currentUser } = useContext(AuthContext);
    const { collapsed } = useSidebarState();
    const containerClass = collapsed ? "homeContainer collapsed" : "homeContainer";
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchCompanyTheme = async () => {
            if (currentUser && currentUser.email) {
                try {
                    const response = await axios.get(`${API_URL}/truesightpage/company_theme?email=${currentUser.email}`);
                    setCompanyTheme(response.data);
                } catch (error) {
                    console.error('Error fetching company theme:', error);
                }
            }
        };

        fetchCompanyTheme();
    }, [currentUser]);

    useEffect(() => {
        if (companyTheme) {
            document.documentElement.style.setProperty('--sidebar-bg-color', companyTheme.sidebar_background_color);
            document.documentElement.style.setProperty('--sidebar-text-color', companyTheme.sidebar_text_color);
            document.documentElement.style.setProperty('--sidebar-hover-color', companyTheme.sidebar_hover_color);
            document.documentElement.style.setProperty('--text-color', companyTheme.text_color);
        }
    }, [companyTheme]);

    const [config, setConfig] = useState({
        id: null,
        username: currentUser.email,
        customer: "",
        subscriber: "",
        zone: "",
        camera: "",
        model: "",
        hour_begin: "00:00",
        hour_end: "23:59",
        week_days: [],
        videoanalysis: ""
    });

    const modelOptions = [
        { value: "truesight_pro", label: "Truesight Pro" },
        { value: "truesight_ultra", label: "Truesight Ultra" },
        { value: "truesight_draw", label: "Truesight Draw" },
        { value: "truesight", label: "Truesight" },
        { value: "truesight_lightning", label: "Truesight Lightning" },
    ];

    const weekDays = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

    useEffect(() => {
        if (location.state?.config) {
            const incomingConfig = location.state.config;
            setConfig(prevConfig => ({
                ...prevConfig,
                ...incomingConfig,
                week_days: incomingConfig.week_days ? incomingConfig.week_days.split(',') : []
            }));
        }
        fetchAllConfigs();
    }, [location.state]);

    const fetchAllConfigs = async () => {
        try {
            const response = await axios.get(`${API_URL}/truesightpage/user_customer_config`);
            setAllConfigs(response.data);
        } catch (error) {
            console.error("Error al obtener las configuraciones:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setConfig(prevConfig => ({
            ...prevConfig,
            [name]: value
        }));
    };

    const handleWeekDayChange = (day) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            week_days: prevConfig.week_days.includes(day)
                ? prevConfig.week_days.filter(d => d !== day)
                : [...prevConfig.week_days, day]
        }));
    };

    const handleSaveConfig = async () => {
        try {
            const configToSave = {
                ...config,
                week_days: config.week_days.join(',')
            };

            if (checkOverlap({ ...config, week_days: config.week_days }, allConfigs)) {
                setErrorMessage("Se detectó una superposición de configuraciones. Por favor, revise los detalles y asegúrese de que no haya conflictos en zona, días y horas para el mismo cliente y abonado.");
                return;
            }

            if (config.id) {
                await axios.put(`${API_URL}/truesightpage/user_customer_config/${config.id}`, configToSave);
            } else {
                await axios.post(`${API_URL}/truesightpage/user_customer_config`, configToSave);
            }
            navigate('/clientRegistry');
        } catch (error) {
            console.error("Error al guardar la configuración:", error);
            setErrorMessage("Ocurrió un error al guardar la configuración. Por favor, inténtelo de nuevo.");
        }
    };


    const checkOverlap = (newConfig, existingConfigs) => {
        for (let config of existingConfigs) {
            // Ignorar la configuración actual si estamos editando
            if (newConfig.id && (newConfig.id.toString() === config.id.toString())) {
                continue;
            }

            // Función helper para comparar campos
            const fieldMatch = (field1, field2) => {
                return field1.toLowerCase() === "todos" ||
                    field2.toLowerCase() === "todos" ||
                    field1.toLowerCase() === field2.toLowerCase();
            };

            // Comprobar si hay coincidencia en cliente, abonado y zona
            const customerMatch = fieldMatch(newConfig.customer, config.customer);
            const subscriberMatch = fieldMatch(newConfig.subscriber, config.subscriber);
            const zoneMatch = fieldMatch(newConfig.zone, config.zone);

            if (customerMatch && subscriberMatch && zoneMatch) {
                // Comprobar superposición de días
                const newWeekDays = Array.isArray(newConfig.week_days) ? newConfig.week_days : newConfig.week_days.split(',');
                const existingWeekDays = Array.isArray(config.week_days) ? config.week_days : config.week_days.split(',');

                const hasCommonDay = newWeekDays.some(day =>
                    existingWeekDays.includes(day) ||
                    day.toLowerCase() === "todos" ||
                    existingWeekDays.includes("todos")
                );

                if (hasCommonDay) {
                    // Comprobar superposición de tiempo
                    const newStart = new Date(`1970-01-01T${newConfig.hour_begin}`);
                    const newEnd = new Date(`1970-01-01T${newConfig.hour_end}`);
                    const existingStart = new Date(`1970-01-01T${config.hour_begin}`);
                    const existingEnd = new Date(`1970-01-01T${config.hour_end}`);

                    // Verificar si los rangos de tiempo se superponen
                    if ((newStart < existingEnd && newEnd > existingStart) ||
                        (existingStart < newEnd && existingEnd > newStart)) {
                        console.log("Superposición detectada:");
                        console.log("Nueva config:", newConfig);
                        console.log("Config existente:", config);
                        return true; // Superposición encontrada
                    }
                }
            }
        }
        return false; // No hay superposición
    };

    return (
        <div className="home">
            <Sidebar />
            <div className={containerClass}>
                <Navbar />
                <div className="user-greeting">
                    <h1>{config.id ? "Actualizar" : "Crear"} Configuración de Cliente</h1>
                    <div className="config-section">
                        {errorMessage && (
                            <Alert severity="error" onClose={() => setErrorMessage("")} style={{ marginBottom: '20px' }}>
                                {errorMessage}
                            </Alert>
                        )}
                        <TextField
                            name="customer"
                            label="Cliente"
                            value={config.customer}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="subscriber"
                            label="Abonado"
                            value={config.subscriber}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="zone"
                            label="Zona"
                            value={config.zone}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="camera"
                            label="Cámara"
                            value={config.camera}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Modelo</InputLabel>
                            <Select
                                name="model"
                                value={config.model}
                                onChange={handleInputChange}
                            >
                                {modelOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            name="hour_begin"
                            label="Hora de inicio"
                            type="time"
                            value={config.hour_begin}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="hour_end"
                            label="Hora de fin"
                            type="time"
                            value={config.hour_end}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <FormGroup>
                            {weekDays.map(day => (
                                <FormControlLabel
                                    key={day}
                                    control={
                                        <Checkbox
                                            checked={config.week_days.includes(day)}
                                            onChange={() => handleWeekDayChange(day)}
                                        />
                                    }
                                    label={day}
                                />
                            ))}
                        </FormGroup>
                        <TextField
                            name="videoanalysis"
                            label="Video Análisis"
                            value={config.videoanalysis}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveConfig}
                            style={{ marginTop: '20px' }}
                        >
                            {config.id ? "Actualizar" : "Guardar"} Configuración
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientConfig;