import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./clientRegistry.scss";
import { AuthContext } from "../../context/AuthContext";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Alert,
    Snackbar,
    TablePagination
} from "@mui/material";
import { FormControlLabel, Checkbox } from "@mui/material";
import axios from "axios";
import { Delete as DeleteIcon, Edit as UpdateIcon } from '@mui/icons-material';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { API_URL } from '../../config';

const ClientRegistry = () => {
    const { currentUser } = useContext(AuthContext);
    const { collapsed } = useSidebarState();
    const containerClass = collapsed ? "homeContainer collapsed" : "homeContainer";

    const [username, setUsername] = useState(currentUser.email); // Username prellenado con el email del usuario actual
    const [customer, setCustomer] = useState("");
    const [subscribers, setSubscribers] = useState("Todos");
    const [zone, setZone] = useState("Todos");
    const [camera, setCamera] = useState("Todos");
    const [model, setModel] = useState("");
    const [hourBegin, setHourBegin] = useState("00:00");
    const [hourEnd, setHourEnd] = useState("23:59");
    const [weekDays, setWeekDays] = useState([]);
    const [videoanalitica, setVideoanalitica] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [companyTheme, setCompanyTheme] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const [userConfigs, setUserConfigs] = useState([]);
    const [companyConfigs, setCompanyConfigs] = useState([]);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [configToDelete, setConfigToDelete] = useState(null);
    const [allConfigs, setAllConfigs] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanyTheme = async () => {
            if (currentUser && currentUser.email) {
                try {
                    const response = await axios.get(`${API_URL}/truesightpage/company_theme?email=${currentUser.email}`);
                    setCompanyTheme(response.data);
                } catch (error) {
                    console.error('Error fetching company theme:', error);
                }
            }
        };

        fetchCompanyTheme();
    }, [currentUser]);

    useEffect(() => {
        if (currentUser && currentUser.email) {
            const company = getCompanyFromEmail(currentUser.email);
            setCustomer(company);
        }
    }, [currentUser]);


    useEffect(() => {
        if (companyTheme) {
            document.documentElement.style.setProperty('--sidebar-bg-color', companyTheme.sidebar_background_color);
            document.documentElement.style.setProperty('--sidebar-text-color', companyTheme.sidebar_text_color);
            document.documentElement.style.setProperty('--sidebar-hover-color', companyTheme.sidebar_hover_color);
            document.documentElement.style.setProperty('--text-color', companyTheme.text_color);
        }
    }, [companyTheme]);

    // Asegúrate de que esta función esté definida en tu componente
    const getCompanyFromEmail = (email) => {
        const domain = email.split('@')[1];
        return domain.split('.')[0];
    };


    // Opciones para el selector de modelos
    const modelOptions = [
        { value: "truesight_lightning", label: "Truesight Lightning" },
        { value: "truesight", label: "Truesight" },
        { value: "truesight_pro", label: "Truesight Pro" },
        { value: "truesight_ultra", label: "Truesight Ultra" },
        { value: "truesight_draw", label: "Truesight Draw" },
    ];

    // Opciones para el selector de días de la semana
    const weekDaysOptions = [
        { value: "Lunes", label: "Lunes" },
        { value: "Martes", label: "Martes" },
        { value: "Miercoles", label: "Miércoles" },
        { value: "Jueves", label: "Jueves" },
        { value: "Viernes", label: "Viernes" },
        { value: "Sabado", label: "Sábado" },
        { value: "Domingo", label: "Domingo" },
    ];

    // Función para obtener todas las configuraciones de cliente
    useEffect(() => {
        const fetchAllConfigs = async () => {
            try {
                const response = await axios.get(`${API_URL}/truesightpage/user_customer_config`);
                setAllConfigs(response.data);
            } catch (error) {
                console.error("Error al obtener las configuraciones de cliente:", error);
            }
        };
        fetchAllConfigs();
    }, []);

    const fetchCompanyConfigs = async () => {
        try {
            const companyDomain = currentUser.email.split('@')[1];
            const response = await axios.get(`${API_URL}/truesightpage/company_customer_config/${companyDomain}`);
            setCompanyConfigs(response.data);
        } catch (error) {
            console.error("Error al obtener las configuraciones de la empresa:", error);
        }
    };

    const checkOverlap = (newConfig, existingConfigs) => {
        for (let config of existingConfigs) {
            const zoneMatch = newConfig.zone.toLowerCase() === "todos" ||
                config.zone.toLowerCase() === "todos" ||
                newConfig.zone === config.zone;

            const customerMatch = newConfig.customer.toLowerCase() === "todos" ||
                config.customer.toLowerCase() === "todos" ||
                newConfig.customer === config.customer;

            const subscriberMatch = newConfig.subscriber.toLowerCase() === "todos" ||
                config.subscriber.toLowerCase() === "todos" ||
                newConfig.subscriber === config.subscriber;

            if (zoneMatch && customerMatch && subscriberMatch) {
                const newWeekDays = newConfig.week_days.split(',');
                const existingWeekDays = config.week_days.split(',');

                const hasCommonDay = newWeekDays.some(day =>
                    existingWeekDays.includes(day) ||
                    day.toLowerCase() === "todos" ||
                    existingWeekDays.includes("todos")
                );

                if (hasCommonDay) {
                    const newStart = new Date(`1970-01-01T${newConfig.hour_begin}`);
                    const newEnd = new Date(`1970-01-01T${newConfig.hour_end}`);
                    const existingStart = new Date(`1970-01-01T${config.hour_begin}`);
                    const existingEnd = new Date(`1970-01-01T${config.hour_end}`);

                    if ((newStart >= existingStart && newStart < existingEnd) ||
                        (newEnd > existingStart && newEnd <= existingEnd) ||
                        (newStart <= existingStart && newEnd >= existingEnd)) {
                        return true; // Overlap found
                    }
                }
            }
        }
        return false; // No overlap
    };

    // Función para registrar la configuración de cliente
    const registerUserConfig = async () => {
        const subscriberList = subscribers.split(',').map(subscriber => subscriber.trim());

        const newUserConfigs = subscriberList.map(subscriber => ({
            username,
            customer,
            subscriber: subscriber || "Todos",
            zone: zone || "Todos",
            camera: camera || "Todos",
            model,
            hour_begin: `${hourBegin}:00`,
            hour_end: `${hourEnd}:59`,
            week_days: weekDays.join(","),
            videoanalysis: videoanalitica || ""
        }));

        try {
            // Check for overlaps
            const currentAllConfigs = [...allConfigs];
            for (let newConfig of newUserConfigs) {
                if (checkOverlap(newConfig, currentAllConfigs)) {
                    setErrorMessage("Se detectó una superposición de configuraciones. Por favor, revise los detalles y asegúrese de que no haya conflictos en zona, días y horas para el mismo cliente y abonado.");
                    return;
                }
            }

            // If no overlaps, proceed with registration
            const responses = await Promise.all(newUserConfigs.map(config =>
                axios.post(`${API_URL}/truesightpage/user_customer_config`, config)
            ));

            // Update allConfigs and companyConfigs states
            const newConfigs = responses.map(res => res.data);
            setAllConfigs(prevConfigs => [...prevConfigs, ...newConfigs]);
            setCompanyConfigs(prevConfigs => [...prevConfigs, ...newConfigs]);

            console.log("Nuevos registros de configuración de cliente:", newConfigs);

            clearFormFields();
            showSnackbar("Cliente registrado correctamente");
        } catch (error) {
            console.error("Error al registrar las configuraciones de cliente:", error);
            setErrorMessage("Ocurrió un error al registrar las configuraciones. Por favor, inténtelo de nuevo.");
        }
    };

    const handleUpdateClick = (config) => {
        // Navigate to ClientConfig with the selected config data
        navigate('/clientConfig', { state: { config } });
    };

    const handleDeleteClick = (config) => {
        setConfigToDelete(config);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (configToDelete && configToDelete.id) {
            try {
                const response = await axios.delete(`${API_URL}/truesightpage/user_customer_config/by-id/${configToDelete.id}`);
                if (response.status === 200) {
                    // Update both userConfigs and companyConfigs
                    setUserConfigs(prevConfigs => prevConfigs.filter(config => config.id !== configToDelete.id));
                    setCompanyConfigs(prevConfigs => prevConfigs.filter(config => config.id !== configToDelete.id));
                    setAllConfigs(prevConfigs => prevConfigs.filter(config => config.id !== configToDelete.id)); 
                    setDeleteConfirmOpen(false);
                    setConfigToDelete(null);
                    // Show a success message
                    showSnackbar("Configuración eliminada correctamente");
                }
            } catch (error) {
                console.error("Error al eliminar la configuración de usuario:", error);
                if (error.response) {
                    console.error('Error response:', error.response.data);
                    console.error('Error status:', error.response.status);
                }
                // Show an error message
                setErrorMessage("Error al eliminar la configuración. Por favor, inténtelo de nuevo.");
            }
        } else {
            console.error('configToDelete is null or undefined, or does not have an id property');
        }
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
        setConfigToDelete(null);
    };

    // Función para obtener las configuraciones de cliente del usuario conectado

    const fetchUserConfigs = async () => {
        try {
            const response = await axios.get(`${API_URL}/truesightpage/user_customer_config/${currentUser.email}`);
            setUserConfigs(response.data);
        } catch (error) {
            console.error("Error al obtener las configuraciones de cliente:", error);
        }
    };

    useEffect(() => {
        fetchUserConfigs();
        fetchCompanyConfigs();
    }, [currentUser.email]);

    // Función para limpiar los campos del formulario después de enviar
    const clearFormFields = () => {
        setSubscribers("Todos");
        setZone("Todos");
        setCamera("Todos");
        setModel("");
        setHourBegin("00:00");
        setHourEnd("23:59");
        setWeekDays([]);
        setVideoanalitica("");  // Añade esta línea
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div data-testid="sidebar3" className="new">
            <Sidebar />
            <div data-testid="navbar3" className={`newContainer ${containerClass}`}>
                <Navbar />
                <div className="top">
                    <h1>Configuración Truesight</h1>
                </div>
                <div className="client-table">
                    <Paper>
                        <h2>Configuraciones de Cliente Registradas (Empresa)</h2>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Usuario</TableCell>
                                    <TableCell>Videoanalitica</TableCell>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Abonado</TableCell>
                                    <TableCell>Zona</TableCell>
                                    <TableCell>Cámara</TableCell>
                                    <TableCell>Modelo</TableCell>
                                    <TableCell>Hora de inicio</TableCell>
                                    <TableCell>Hora de fin</TableCell>
                                    <TableCell>Días de la semana</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {companyConfigs
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((config, index) => (
                                        <TableRow key={config.id || index}>
                                            <TableCell>{config.username}</TableCell>
                                            <TableCell>{config.videoanalysis}</TableCell>
                                            <TableCell>{config.customer}</TableCell>
                                            <TableCell>{config.subscriber}</TableCell>
                                            <TableCell>{config.zone}</TableCell>
                                            <TableCell>{config.camera}</TableCell>
                                            <TableCell>{config.model}</TableCell>
                                            <TableCell>{config.hour_begin}</TableCell>
                                            <TableCell>{config.hour_end}</TableCell>
                                            <TableCell>{config.week_days}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => handleDeleteClick(config)}
                                                    aria-label="delete"
                                                    style={{ color: 'red' }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => handleUpdateClick(config)}
                                                    aria-label="update"
                                                    style={{ color: 'blue' }}
                                                >
                                                    <UpdateIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={companyConfigs.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
                <div className="bottom">
                    <div className="right">
                        <form className="client-form" onSubmit={(e) => {
                            e.preventDefault();
                            registerUserConfig();
                        }}>
                            <h2>Registro de Datos</h2>
                            <br></br>
                            <div className="inputPairs">
                                <TextField
                                    id="videoanalitica"
                                    label="Videoanalitica"
                                    value={videoanalitica}
                                    onChange={(e) => setVideoanalitica(e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    id="username"
                                    label="Nombre de usuario"
                                    value={username}
                                    InputProps={{ readOnly: true }} // Campo de solo lectura
                                    fullWidth
                                    required
                                />
                                <TextField
                                    id="customer"
                                    label="Cliente"
                                    value={customer}
                                    onChange={(e) => setCustomer(e.target.value)}
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="inputPairs">
                                <TextField
                                    id="subscribers"
                                    label="Abonados"
                                    value={subscribers}
                                    onChange={(e) => setSubscribers(e.target.value)}
                                    fullWidth
                                    required
                                    helperText="Separar múltiples abonados con comas"
                                />
                                <TextField
                                    id="zone"
                                    label="Zona"
                                    value={zone}
                                    onChange={(e) => setZone(e.target.value)}
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="inputPairs">
                                <TextField
                                    id="camera"
                                    label="Cámara"
                                    value={camera}
                                    onChange={(e) => setCamera(e.target.value)}
                                    fullWidth
                                    required
                                />
                                <h2>Configuración de Datos</h2>

                                <FormControl fullWidth>
                                    <InputLabel id="model-label">Modelo</InputLabel>
                                    <Select
                                        labelId="model-label"
                                        id="model"
                                        value={model}
                                        onChange={(e) => setModel(e.target.value)}
                                        label="Modelo"
                                        fullWidth
                                        required
                                    >
                                        {modelOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="inputPairs">
                                <TextField
                                    id="hourBegin"
                                    label="Hora de inicio"
                                    type="time"
                                    value={hourBegin}
                                    onChange={(e) => setHourBegin(e.target.value)}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    id="hourEnd"
                                    label="Hora de fin"
                                    type="time"
                                    value={hourEnd}
                                    onChange={(e) => setHourEnd(e.target.value)}
                                    fullWidth
                                    required
                                />
                            </div>
                            <FormControl fullWidth>
                                <InputLabel id="weekDays-label">Días de la semana</InputLabel>
                                <Select
                                    labelId="weekDays-label"
                                    id="weekDays"
                                    multiple
                                    value={weekDays}
                                    onChange={(e) => setWeekDays(e.target.value)}
                                    label="Días de la semana"
                                    fullWidth
                                    required
                                >
                                    {weekDaysOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {errorMessage && (
                                <Alert severity="error" onClose={() => setErrorMessage("")}>
                                    {errorMessage}
                                </Alert>
                            )}
                            <div className="button-container">
                                <Button variant="contained" color="primary" type="submit">
                                    Registrar Configuración
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Dialog
                open={deleteConfirmOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea borrar esta configuración?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ClientRegistry;
