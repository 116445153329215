import "./sidebar.scss";
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import axios from 'axios';
import { Link } from "react-router-dom";
import logo from "../../images/logo.png"
import logoCollapsed from "../../images/logoCollapsed.png"
import { useSidebarState } from './SidebarStateContext';
import { API_URL } from '../../config';

const Sidebar = () => {
  const [companyTheme, setCompanyTheme] = useState(null);
  const [logoUrl, setLogoUrl] = useState(logo);
  const [logoCollapsedUrl, setLogoCollapsedUrl] = useState(logoCollapsed);
  const [companyName, setCompanyName] = useState('TRUESIGHT');

  const { collapsed } = useSidebarState();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchCompanyTheme = async () => {
      if (currentUser && currentUser.email) {
        try {
          const response = await axios.get(`${API_URL}/truesightpage/company_theme?email=${currentUser.email}`);
          setCompanyTheme(response.data);
          
          // Actualizar logo, logo colapsado y nombre de la compañía
          if (response.data.logo_url) setLogoUrl(response.data.logo_url);
          if (response.data.logo_collapsed_url) setLogoCollapsedUrl(response.data.logo_collapsed_url);
          if (response.data.company_name) setCompanyName(response.data.company_name);

          console.log('Fetched company theme:', response.data);
        } catch (error) {
          console.error('Error fetching company theme:', error);
        }
      }
    };

    fetchCompanyTheme();
  }, [currentUser]);

  useEffect(() => {
    if (companyTheme) {
      document.documentElement.style.setProperty('--sidebar-bg-color', companyTheme.sidebar_background_color);
      document.documentElement.style.setProperty('--sidebar-text-color', companyTheme.sidebar_text_color);
      document.documentElement.style.setProperty('--sidebar-hover-color', companyTheme.sidebar_hover_color);
      document.documentElement.style.setProperty('--text-color', companyTheme.text_color);
    }
  }, [companyTheme]);

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          {!collapsed && (
            <span className="logo">
              <img src={logoUrl} alt="logo" />
              {companyName}
            </span>
          )}
          {collapsed && (
            <span className="logoCollapsed">
              <img src={logoCollapsedUrl} alt="logo" />
            </span>
          )}
        </Link>
      </div>

      <hr />
      <div className="center">
        <ul>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardOutlinedIcon className="icon" />
              <span className="link-text">Configuración general</span>
            </li>
          </Link>
          <Link to="/clientRegistry" style={{ textDecoration: "none" }}>
            <li>
              <NotificationsActiveIcon className="icon" />
              <span className="link-text">Configuración Truesight</span>
            </li>
          </Link>
          <Link to="/GrafanaDashboard" style={{ textDecoration: "none" }}>
            <li>
              <DashboardOutlinedIcon className="icon" />
              <span className="link-text">Dashboard</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom">
        <div className="privacy-policy-link">
          <Link to="/privacy-policy">Política de Privacidad</Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;