import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { AuthContext } from "../../context/AuthContext";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import "./home.scss";

const GrafanaDashboard = () => {
    const { currentUser } = useContext(AuthContext);
    const { collapsed } = useSidebarState();
    const containerClass = collapsed ? "homeContainer collapsed" : "homeContainer";
    const location = useLocation();

    const iframes = [
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=ca2df244-387a-449d-9076-71e9866e8b2f&var-server=prod_&from=1721627501931&to=1721649101931&panelId=28", width: "100%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339297567&to=1721360897567&panelId=18", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339319199&to=1721360919199&panelId=30", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339329569&to=1721360929569&panelId=40", width: "100%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339368555&to=1721360968555&panelId=36", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339376129&to=1721360976129&panelId=37", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339385359&to=1721360985359&panelId=15", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339394988&to=1721360994988&panelId=34", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339404373&to=1721361004373&panelId=35", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339414311&to=1721361014311&panelId=25", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339424380&to=1721361024380&panelId=41", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339434260&to=1721361034260&panelId=42", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339442721&to=1721361042721&panelId=43", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339450716&to=1721361050716&panelId=44", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339463675&to=1721361063675&panelId=6", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339473387&to=1721361073387&panelId=7", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339482952&to=1721361082952&panelId=5", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339493699&to=1721361093699&panelId=4", width: "48%" },
        { src: "//grafana.gruposecoex.com:3000/d-solo/d98f6a17-fb91-4c51-882b-17ef1371d270/aidaley-metrics?orgId=1&var-datasource=b2e72c93-739a-4562-9751-1a7b28108649&from=1721339504285&to=1721361104285&panelId=19", width: "48%" },
    ];

    return (
        <div className="home">
            <Sidebar />
            <div className={containerClass}>
                <Navbar />
                <div className="dashboard-content">
                <div className="user-greeting">
                    <h1>DASHBOARD MÉTRICAS TRUESIGHT</h1>
                </div>
                    <div className="grafana-iframe-container">
                        {iframes.map((iframe, index) => (
                            <div key={index} style={{ width: iframe.width }}>
                                <iframe 
                                    src={iframe.src} 
                                    width="100%" 
                                    height="200" 
                                    frameBorder="0"
                                    title={`Grafana Panel ${index + 1}`}
                                ></iframe>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrafanaDashboard;